var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/Bidding" } } },
                [_vm._v("竞卖中心")]
              ),
              _c("el-breadcrumb-item", [_vm._v("竞卖详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "image-box" }, [
              _c("img", { attrs: { src: _vm.tableData.picUrl, alt: "" } }),
            ]),
            _c(
              "div",
              { staticClass: "info-right" },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.tableData.prodName)),
                ]),
                _vm.tableData.status !== "bided"
                  ? _c("div", { staticClass: "count-down-box" }, [
                      _vm.tableData.status === "preBid"
                        ? _c("span", { staticClass: "tit" }, [_vm._v("预告中")])
                        : _vm._e(),
                      _vm.tableData.status === "bidding"
                        ? _c("span", { staticClass: "tit" }, [_vm._v("进行中")])
                        : _vm._e(),
                      _vm.tableData.status === "bided"
                        ? _c("span", { staticClass: "tit" }, [_vm._v("已结束")])
                        : _vm._e(),
                      _c("div", { staticClass: "count-down-info" }, [
                        _vm.tableData.status === "preBid"
                          ? _c("span", [_vm._v("距离开始仅剩：")])
                          : _vm._e(),
                        _vm.tableData.status === "bidding"
                          ? _c("span", [_vm._v("距离结束仅剩：")])
                          : _vm._e(),
                        _vm.day > 0
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.day)),
                            ])
                          : _vm._e(),
                        _vm.day > 0
                          ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                          : _vm._e(),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.hour)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.minute)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.second)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "box1-wrap" },
                  [
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("当前价")]),
                      _c("p", { staticClass: "price" }, [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm.tableData.nowAmount || _vm.tableData.upPrice
                            )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("起拍价")]),
                      _c("p", [_vm._v("￥" + _vm._s(_vm.tableData.upPrice))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("降价幅度")]),
                      _c("p", [
                        _vm._v(
                          "￥" + _vm._s(_vm.tableData.failTactics) + "/分钟"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("数量")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.tableData.amount) +
                            _vm._s(_vm.tableData.units)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("开始时间")]),
                      _c("p", [_vm._v(_vm._s(_vm.tableData.startTime))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("结束时间")]),
                      _c("p", [_vm._v(_vm._s(_vm.tableData.endTime))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("订单模式")]),
                      _c("p", [_vm._v(_vm._s(_vm.tableData.orderProcess))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("付款方式")]),
                      _c("p", [_vm._v(_vm._s(_vm.tableData.payMode))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _vm.tableData.payMode === "商业承兑汇票" &&
                      _vm.tableData.bankBillTerm != null
                        ? _c("p", [
                            _c("span", [_vm._v("承兑汇票期限：")]),
                            _vm._v(_vm._s(_vm.tableData.bankBillTerm) + "个月"),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("合同编号")]),
                      _c("p", [_vm._v(_vm._s(_vm.tableData.contractNumber))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("合同信息：")]),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "blue", cursor: "pointer" },
                          on: { click: _vm.clickContractShow },
                        },
                        [_vm._v("查看合同模板")]
                      ),
                    ]),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "合同模板",
                          visible: _vm.contractDialogVisible,
                          width: "700px",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.contractDialogVisible = $event
                          },
                        },
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.contractContent) },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.tableData.status === "bidding"
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.readOnly === true
                            ? "readOnly addCart"
                            : "addCart",
                        ],
                        on: { click: _vm.addCart },
                      },
                      [_vm._v("出价")]
                    )
                  : _vm.clientAlike
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/User/TransactionDetail",
                            query: {
                              orderid: _vm.orderId,
                              customer: _vm.customer,
                              orderType: _vm.orderType,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.readOnly === true
                                ? "readOnly addCart"
                                : "addCart",
                            ],
                          },
                          [_vm._v("进入订单")]
                        ),
                      ]
                    )
                  : _vm.tableData.status === "bided"
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.readOnly === true
                            ? "readOnly addCart"
                            : "addCart",
                        ],
                        on: { click: _vm.addCart },
                      },
                      [_vm._v("已结束")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.tableData.status === "bided"
            ? _c("div", { staticClass: "explain" }, [
                _c("h3", [_vm._v("竞卖结果")]),
                _c(
                  "div",
                  { staticClass: "explain-info" },
                  [
                    _vm.isLogin
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "中标方：" +
                                  _vm._s(
                                    _vm._f("hideTextInfo")(
                                      _vm.auction.client,
                                      3,
                                      4
                                    )
                                  )
                              ),
                            ]),
                            _c("span", [
                              _vm._v("竞拍价：" + _vm._s(_vm.auction.price)),
                            ]),
                            _c("span", [
                              _vm._v("竞拍时间：" + _vm._s(_vm.auction.time)),
                            ]),
                          ]
                        )
                      : _c("empty", { attrs: { text: "登录后可见" } }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.orderInfo
            ? _c("div", { staticClass: "explain" }, [
                _c("h3", [_vm._v("订单流程")]),
                ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#fff",
                          padding: "20px 20px 10px",
                        },
                      },
                      [
                        _vm.tableData.orderProcess == "先货后款"
                          ? [
                              _c(
                                "el-steps",
                                {
                                  attrs: {
                                    active: _vm.flowStep,
                                    "align-center": "",
                                  },
                                },
                                [
                                  _c("el-step", {
                                    attrs: { title: "确认订单" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "合同签署" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商发货" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家签收" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商开发票" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家支付" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "供应商收款" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.tableData.orderProcess == "先款后货"
                          ? [
                              _c(
                                "el-steps",
                                {
                                  attrs: {
                                    active: _vm.flowStep,
                                    "align-center": "",
                                  },
                                },
                                [
                                  _c("el-step", {
                                    attrs: { title: "确认订单" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "合同签署" },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家付款" },
                                  }),
                                  _c("el-step", {
                                    attrs: {
                                      title: "供应商确认收款、发货、开发票",
                                    },
                                  }),
                                  _c("el-step", {
                                    attrs: { title: "买家签收" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "explain-info" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.orderInfo.orderStatu === "QXC"
                              ? "订单取消待确认"
                              : "订单已取消成功"
                          ) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("竞卖说明")]),
            _c(
              "div",
              { staticClass: "explain-info" },
              [
                _vm.isLogin
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.tableData.autionDetails),
                      },
                    })
                  : _c("empty", { attrs: { text: "登录后可见" } }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "publish" }, [
            _c("h3", [_vm._v("企业信息")]),
            _c("div", { staticClass: "publish-info" }, [
              _c("p", [
                _vm._v(
                  "公司名称：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(_vm.tableData.clientName, 3, 4)
                    )
                ),
              ]),
              _c("p", [
                _vm._v(
                  "公司地址：" +
                    _vm._s(_vm._f("hideTextInfo")(_vm.tableData.offAddr, 3, 4))
                ),
              ]),
              _c("p", [
                _vm._v(
                  "联系电话：" +
                    _vm._s(_vm._f("hideTextInfo")(_vm.tableData.tel, 3, 4))
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "publish", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("最新铝锭价")]),
              _c("div", { staticClass: "publish-info" }, [
                _c("p", [
                  _vm._v(" 南海灵通铝锭价(含票)："),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("￥" + _vm._s(_vm.lvPrice.taxPrice || 0)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "铝锭价公布日期：" + _vm._s(_vm.lvPrice.publishDay || "--")
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "bidding" }, [
            _c("h3", [_vm._v("正在竞卖")]),
            _c(
              "div",
              { staticClass: "bidding-list" },
              _vm._l(_vm.biddingList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "bidding-item",
                    on: {
                      click: function ($event) {
                        return _vm.other(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "bidding-image" }, [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.picUrl, alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "bidding-info" }, [
                      _c("p", [_vm._v(_vm._s(item.prodName))]),
                      _c("p", [
                        _vm._v(
                          "数量：" + _vm._s(item.amount) + _vm._s(item.units)
                        ),
                      ]),
                      _c("p", [_vm._v("起拍价：" + _vm._s(item.upPrice))]),
                      _c("p", [
                        _vm._v(" 当前价："),
                        _c("span", { staticClass: "price" }, [
                          _vm._v("￥" + _vm._s(item.currPic)),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm.biddingStatus.show
          ? _c("div", { staticClass: "bidding-status" }, [
              _c(
                "div",
                {
                  staticClass: "good-box",
                  class: [
                    _vm.biddingStatus.status === "success" ? "bg1" : "bg2",
                  ],
                },
                [
                  _c("div", { staticClass: "good-box-in" }, [
                    _c(
                      "div",
                      { staticClass: "img-box" },
                      [
                        _c("el-image", {
                          staticStyle: { "object-fit": "fill" },
                          attrs: { src: _vm.tableData.picUrl },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "info" }, [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.tableData.prodName)),
                      ]),
                      _c("p", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "tit" }, [
                          _vm._v("规格型号"),
                        ]),
                        _vm._v("：" + _vm._s(_vm.tableData.prodModels)),
                      ]),
                      _c("p", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "tit" }, [_vm._v("数量")]),
                        _vm._v(
                          "：" +
                            _vm._s(_vm.tableData.amount + _vm.tableData.units)
                        ),
                      ]),
                      _c("p", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "tit" }, [_vm._v("当前价")]),
                        _vm._v(": "),
                        _c("span", { staticClass: "sign" }, [_vm._v("￥")]),
                        _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(
                              _vm.tableData.nowAmount || _vm.tableData.upPrice
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm.biddingStatus.status === "success"
                ? _c(
                    "div",
                    { staticClass: "status-box" },
                    [
                      _vm._m(0),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/home/Bidding/ConfirmOrder?pagesName=BiddingConfirmOrdera&clientId=" +
                              _vm.tableData.clientInfoId +
                              "&autionInfoId=" +
                              _vm.autionInfoId,
                          },
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("完善订单信息"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.biddingStatus.status === "error"
                ? _c(
                    "div",
                    { staticClass: "status-box" },
                    [
                      _vm._m(1),
                      _c(
                        "router-link",
                        { attrs: { to: "/home/Bidding" } },
                        [
                          _c("el-button", { attrs: { plain: "" } }, [
                            _vm._v("返回竞卖中心"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm.biddingLoading
        ? _c("div", { staticClass: "bidding-loading" }, [_vm._m(2)])
        : _vm._e(),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-box-info" }, [
      _c("i", { staticClass: "el-icon-success" }),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "txst" }, [_vm._v("竞卖成功")]),
        _c("p", { staticClass: "desc" }, [
          _vm._v("恭喜您，竞卖成功，请完善订单信息"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-box-info" }, [
      _c("i", { staticClass: "el-icon-error" }),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "txst" }, [_vm._v("竞卖失败")]),
        _c("p", { staticClass: "desc" }, [
          _vm._v("很遗憾，竞卖失败，请选择其他产品竞卖"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-info" }, [
      _c("div", { staticClass: "dotting" }, [
        _c("div", { staticClass: "dotting-item dotting1" }),
        _c("div", { staticClass: "dotting-item dotting2" }),
        _c("div", { staticClass: "dotting-item dotting3" }),
      ]),
      _c("p", [_vm._v("竞卖中")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }