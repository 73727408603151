<template>
  <div class="wrap">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/home/HomePage'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/home/Bidding'}">竞卖中心</el-breadcrumb-item>
        <el-breadcrumb-item>竞卖详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="main">
        <div class="info">
          <div class="image-box">
            <img :src="tableData.picUrl" alt="" />
          </div>

          <div class="info-right">
            <div class="name">{{ tableData.prodName }}</div>
            <div class="count-down-box" v-if="tableData.status !== 'bided'">
              <span class="tit" v-if="tableData.status === 'preBid'">预告中</span>
              <span class="tit" v-if="tableData.status === 'bidding'">进行中</span>
              <span class="tit" v-if="tableData.status === 'bided'">已结束</span>
              <div class="count-down-info">
                <span v-if="tableData.status === 'preBid'">距离开始仅剩：</span>
                <span v-if="tableData.status === 'bidding'">距离结束仅剩：</span>
                <span class="time" v-if="day > 0">{{ day }}</span>
                <span class="unit" v-if="day > 0">天</span>
                <span class="time">{{ hour }}</span>
                <span class="unit">时</span>
                <span class="time">{{ minute }}</span>
                <span class="unit">分</span>
                <span class="time">{{ second }}</span>
                <span class="unit">秒</span>
              </div>
            </div>
            <div class="box1-wrap">
              <div class="box1">
                <span>当前价</span>
                <p class="price">￥{{ tableData.nowAmount || tableData.upPrice }}</p>
              </div>
              <div class="box1">
                <span>起拍价</span>
                <p>￥{{ tableData.upPrice }}</p>
              </div>
              <div class="box1">
                <span>降价幅度</span>
                <p>￥{{ tableData.failTactics }}/分钟</p>
              </div>
              <div class="box1">
                <span>数量</span>
                <p>{{ tableData.amount }}{{ tableData.units }}</p>
              </div>
              <div class="box1">
                <span>开始时间</span>
                <p>{{ tableData.startTime }}</p>
              </div>
              <div class="box1">
                <span>结束时间</span>
                <p>{{ tableData.endTime }}</p>
              </div>
              <div class="box1">
                <span>订单模式</span>
                <p>{{ tableData.orderProcess }}</p>
              </div>
              <div class="box1">
                <span>付款方式</span>
                <p>{{ tableData.payMode }}</p>
              </div>
              <div class="box1">
                <p v-if="tableData.payMode === '商业承兑汇票' && tableData.bankBillTerm != null"><span>承兑汇票期限：</span>{{ tableData.bankBillTerm }}个月</p>
              </div>
              <div class="box1">
                <span>合同编号</span>
                <p>{{ tableData.contractNumber }}</p>
              </div>
              <div class="box1">
                <span>合同信息：</span>
                <p style="color: blue;cursor: pointer;" @click="clickContractShow">查看合同模板</p>
              </div>
              <!-- 查看合同模板 -->
              <el-dialog title="合同模板" :visible.sync="contractDialogVisible" width="700px">
                <div v-html="contractContent"></div>
              </el-dialog>
            </div>
            <!--<div class="addCart" v-if="goods.money=='面议'">询价</div>-->
            <div v-if="tableData.status === 'bidding'" :class="[readOnly === true ? 'readOnly addCart' : 'addCart']" @click="addCart">出价</div>
            <!--进入订单详情-->
            <router-link v-else-if="clientAlike" :to="{path: '/User/TransactionDetail', query: {orderid: orderId, customer: customer, orderType: orderType}}">
              <div :class="[readOnly === true ? 'readOnly addCart' : 'addCart']">进入订单</div>
            </router-link>

            <div v-else-if="tableData.status === 'bided'" :class="[readOnly === true ? 'readOnly addCart' : 'addCart']" @click="addCart">已结束</div>
          </div>
        </div>

        <!--         竞卖结果-->
        <div v-if="tableData.status === 'bided'" class="explain">
          <h3>竞卖结果</h3>
          <div class="explain-info">
            <div style="display: flex;justify-content: space-between" v-if="isLogin">
              <span>中标方：{{ auction.client | hideTextInfo(3, 4) }}</span>
              <span>竞拍价：{{ auction.price }}</span>
              <span>竞拍时间：{{ auction.time }}</span>
            </div>
            <empty v-else text="登录后可见"></empty>
          </div>
        </div>
        <!-- 订单流程 -->
        <div v-if="orderInfo" class="explain">
          <h3>订单流程</h3>
          <div v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0" style="background:#fff;padding:20px 20px 10px">
            <template v-if="tableData.orderProcess == '先货后款'">
              <el-steps :active="flowStep" align-center>
                <el-step title="确认订单"></el-step>
                <el-step title="合同签署"></el-step>
                <el-step title="供应商发货"></el-step>
                <el-step title="买家签收"></el-step>
                <el-step title="供应商开发票"></el-step>
                <el-step title="买家支付"></el-step>
                <el-step title="供应商收款"></el-step>
                <!-- <el-step title="流程结束"></el-step> -->
              </el-steps>
            </template>
            <template v-if="tableData.orderProcess == '先款后货'">
              <el-steps :active="flowStep" align-center>
                <el-step title="确认订单"></el-step>
                <el-step title="合同签署"></el-step>
                <el-step title="买家付款"></el-step>
                <el-step title="供应商确认收款、发货、开发票"></el-step>
                <el-step title="买家签收"></el-step>
                <!-- <el-step title="流程结束"></el-step> -->
              </el-steps>
            </template>
          </div>
          <div class="explain-info" v-else>
            {{ orderInfo.orderStatu === 'QXC' ? '订单取消待确认' : '订单已取消成功' }}
          </div>
        </div>
        <!--         竞卖说明-->
        <div class="explain">
          <h3>竞卖说明</h3>
          <div class="explain-info">
            <!-- <p><span>付款方式：</span><span>{{tableData.payMode}}</span> -->
            <!--            <p v-if="tableData.payMode === '商业承兑汇票'&& tableData.bankBillTerm != null "><span>承兑汇票期限：</span>{{tableData.bankBillTerm}}个月</p >-->
            <!-- <span v-if='tableData.orderProcess == "先货后款" && tableData.payMode == "商业承兑汇票" && tableData.bankBillTerm'>
              承兑汇票期限为<span  class='tag'> {{tableData.bankBillTerm}}</span>个月</span>
              <span v-if='tableData.orderProcess == "先款后货" && tableData.payMode == "银行转账"'>
              在签订合同后3天内以银行转账方式一次性付清</span>
            <span v-if='tableData.orderProcess == "先款后货" && tableData.payMode == "商业承兑汇票" && tableData.bankBillTerm'>
              在签订合同后3天以内以承兑期限为<span  class='tag'> {{tableData.bankBillTerm}}</span>个月的商业承兑汇票一次性付清</span>
            </p > -->
            <div v-if="isLogin" v-html="tableData.autionDetails"></div>
            <empty v-else text="登录后可见"></empty>
          </div>
        </div>
        <!--        </div>-->

        <!--         其他信息 -->
        <!--        <div v-if="tableData.status === 'bided'" class="explain">-->
        <!--          <h3>其他信息</h3>-->
        <!--          <div class="explain-info">-->
        <!--            <div v-html="goods.detail"></div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <div class="sidebar">
        <div class="publish">
          <h3>企业信息</h3>
          <div class="publish-info">
            <p>公司名称：{{ tableData.clientName | hideTextInfo(3, 4) }}</p>
            <p>公司地址：{{ tableData.offAddr | hideTextInfo(3, 4) }}</p>
            <p>联系电话：{{ tableData.tel | hideTextInfo(3, 4) }}</p>
            <!-- <p>营业执照：<a :href="tableData.businessLicenseImages"  target="_blank"><span class="see">点击查看</span></a></p> -->
          </div>
        </div>
        <div class="publish" style="margin-top:20px">
          <h3>最新铝锭价</h3>
          <div class="publish-info">
            <p>
              南海灵通铝锭价(含票)：<span style="color: red;">￥{{ lvPrice.taxPrice || 0 }}</span>
            </p>
            <p>铝锭价公布日期：{{ lvPrice.publishDay || '--' }}</p>
          </div>
        </div>
        <!-- 正在竞卖 -->
        <div class="bidding">
          <h3>正在竞卖</h3>
          <div class="bidding-list">
            <div class="bidding-item" v-for="item in biddingList" @click="other(item.id)" :key="item.id">
              <div class="bidding-image">
                <img :src="item.picUrl" alt="" style="width: 100%;height: 100%" />
              </div>
              <div class="bidding-info">
                <p>{{ item.prodName }}</p>
                <p>数量：{{ item.amount }}{{ item.units }}</p>
                <p>起拍价：{{ item.upPrice }}</p>
                <p>
                  当前价：<span class="price">￥{{ item.currPic }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 竞卖结果 -->
      <div class="bidding-status" v-if="biddingStatus.show">
        <div class="good-box" :class="[biddingStatus.status === 'success' ? 'bg1' : 'bg2']">
          <div class="good-box-in">
            <div class="img-box">
              <el-image :src="tableData.picUrl" style="object-fit: fill"></el-image>
            </div>
            <div class="info">
              <p class="name">{{ tableData.prodName }}</p>
              <p class="info-item"><span class="tit">规格型号</span>：{{ tableData.prodModels }}</p>
              <p class="info-item"><span class="tit">数量</span>：{{ tableData.amount + tableData.units }}</p>
              <p class="info-item">
                <span class="tit">当前价</span>:
                <span class="sign">￥</span>
                <span class="price">{{ tableData.nowAmount || tableData.upPrice }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="status-box" v-if="biddingStatus.status === 'success'">
          <div class="status-box-info">
            <i class="el-icon-success"></i>
            <div class="info">
              <p class="txst">竞卖成功</p>
              <p class="desc">恭喜您，竞卖成功，请完善订单信息</p>
            </div>
          </div>
          <router-link :to="'/home/Bidding/ConfirmOrder?pagesName=BiddingConfirmOrdera&clientId=' + tableData.clientInfoId + '&autionInfoId=' + autionInfoId">
            <el-button type="primary">完善订单信息</el-button>
          </router-link>
        </div>

        <div class="status-box" v-if="biddingStatus.status === 'error'">
          <div class="status-box-info">
            <i class="el-icon-error"></i>
            <div class="info">
              <p class="txst">竞卖失败</p>
              <p class="desc">很遗憾，竞卖失败，请选择其他产品竞卖</p>
            </div>
          </div>
          <router-link to="/home/Bidding">
            <el-button plain>返回竞卖中心</el-button>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 竞卖中 -->
    <div class="bidding-loading" v-if="biddingLoading">
      <div class="loading-info">
        <div class="dotting">
          <div class="dotting-item dotting1"></div>
          <div class="dotting-item dotting2"></div>
          <div class="dotting-item dotting3"></div>
        </div>
        <p>竞卖中</p>
      </div>
    </div>
    <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event" />
  </div>
</template>

<script>
import CFCATool from '@/components/CFCATool';
import {autionInfoDteail, selectIsAution, readAutionInfoById, findById, selectIsAutionById, newestLvdingPrice} from '../api';
import {hideTextInfo, formatNewDate} from '@/utils/util';
//
// const dtsCategory = {
//   1001001: '热系板卷',
//   1001002: '冷系板卷',
//   1001003: '硅钢',
//   1001004: '钢筋',
//   1001006: '不锈钢',
//   1001007: '中厚板',
//   1001008: '钢管',
//   1001009: '型材',
//   1001010: '特钢',
//   1001011: '铝产品',
// }
import empty from '../component/empty';
import merge from 'webpack-merge';
import {findByClientInfoId, contractTextModel} from '../api';

export default {
  components: {
    empty,
    CFCATool
  },
  created() {
    this.autionInfoId = this.$route.query.autionInfoId;
    this.findByAutionInId();
    // this.contractTextModel();
    this.newestLvdingPrice();
  },

  data() {
    return {
      contractDialogVisible: false,
      contractContent: '',
      auction: {
        client: '',
        price: '',
        time: ''
      },
      releaseAdmin: '',
      mySelf: true,
      isLogin: Boolean(localStorage.getItem('loginName')),
      readOnly: false,
      autionInfoId: undefined, //拍卖信息表id
      tableData: {
        id: undefined,
        isAution: undefined,
        amount: undefined,
        continueTime: undefined,
        dtsCategoryId: undefined,
        picUrl: undefined,
        prodName: undefined,
        startTime: undefined,
        upPrice: undefined,
        nowAmount: undefined,
        endTime: undefined,
        pay_mode: undefined,
        bank_bill_term: undefined,
        type: undefined,
        clientName: undefined,
        offAddr: undefined,
        socialCreditCode: undefined,
        tel: undefined,
        businessLicenseImages: undefined,
        autionDetails: undefined,
        clientInfoId: undefined,
        orderProcess: undefined
      },
      tableData2: {},
      day: 0,
      hour: '00',
      minute: '00',
      second: '00',
      _timeout: null,
      goods: {},
      publishInfo: {},
      biddingList: [],
      cart: {
        goodsId: '',
        goodsName: '',
        price: '',
        number: 1,
        specifications: [],
        picUrl: ''
      },
      biddingLoading: false,
      biddingStatus: {
        show: false,
        status: ''
      },
      certInfo: '',
      //判断拍下的公司是否和登录人公司相同
      clientAlike: false,
      //当前商品成交的订单id
      orderId: undefined,
      customer: '',
      orderType: 'AU',
      flowStep: 0,
      orderInfo: null,
      lvPrice: {}
    };
  },
  mounted() {},
  filters: {
    hideTextInfo
  },
  methods: {
    bidingStatue(startTime, endTime) {
      this.readOnly = true;
      if (Date.parse(formatNewDate(startTime)) > Date.parse(new Date())) {
        return 'preBid';
      } else if (Date.parse(formatNewDate(endTime)) > Date.parse(new Date())) {
        this.readOnly = false;
        return 'bidding';
      }
      return 'bided';
    },

    findByClientInfoId(clientInfoId) {
      var _this = this;
      //查询当前企业正在竞卖的产品
      findByClientInfoId({
        clientInfoId: clientInfoId,
        autionInfoId: this.autionInfoId
      }).then(res => {
        // this.tableData.payMode=='YHZZ' ? this.tableData.payMode = '银行转账' : this.tableData.payMode = '商业承兑汇票'
 
      
    let changePayMode =  this.tableData.payMode.split('|').map((item)=>{
         if (item == 'YHZZ') {
        return    item = '银行转账'
          } else if (item == 'CDHP') {
        return   item = '商业承兑汇票'
          } else if (item == "YHHP") {
         return   item = '银行承兑汇票'
          }    
      })
 this.tableData.payMode=changePayMode.join("|")

        if (res.data.length == 0) {
          _this.biddingList = [];
          return;
        }
        _this.biddingList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (i == 3) {
            //控制显示数量
            break;
          }
          _this.biddingList.push(res.data[i]);
        }
        // 计算当前价
        for (let i = 0; i < _this.biddingList.length; i++) {
          const now = Date.parse(new Date());
          const over = now - Date.parse(formatNewDate(_this.biddingList[i].startTime)); //当前时间-开始时间
          const overSecond = parseInt(over / 1000 / 60); //已经持续的时间
          if (overSecond < _this.biddingList[i].continueTime) {
            //已经持续的时间<持续时间
            //  var currPic = (Number(_this.biddingList[i].upPrice) - overSecond * Number(_this.biddingList[i].failTactics));
            var currPic = parseFloat((Number(_this.biddingList[i].upPrice) - overSecond * Number(_this.biddingList[i].failTactics)).toFixed(2));
          }
          if (currPic < _this.biddingList[i].bottomPrice) {
            currPic = _this.biddingList[i].bottomPrice;
          }
          currPic = String(currPic);
          _this.biddingList[i].currPic = currPic;
        }
      });
    },
    selectAutionById() {
      var _this = this;
      selectIsAutionById({autionId: this.autionInfoId}).then(res => {
        if (res.errno === 0) {
          if (Object.keys(res.data).length) {
            _this.auction.client = res.data.byTClientInfoId.clientName;
            _this.auction.price = res.data.tAutionDeal.amount;
            _this.auction.time = res.data.tAutionDeal.autionTime;
            _this.clientAlike = res.data.clientAlike;
            _this.orderId = res.data.order.id;
            _this.customer = res.data.customer;
            _this.orderInfo = res.data.order;

            if (res.data.order) {
              if (res.data.order.orderProcess === 'KH') {
                ['DFWQ', 'DQS'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 1);
                ['DZF'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 2);
                ['DFH', 'DKP'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 3);
                ['DSH'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 4);
                ['END'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 5);
              }

              if (res.data.order.orderProcess === 'HK') {
                ['DFWQ', 'DQS'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 1);
                ['DFH'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 2);
                ['DSH'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 3);
                ['DKP'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 4);
                ['DZF'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 5);
                ['ZFC'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 6);
                ['END'].indexOf(res.data.order.orderStatus) >= 0 && (this.flowStep = 7);
              }
            }
          } else {
            _this.orderInfo = null;
          }
          if (this.clientAlike) {
            this.readOnly = false;
          }
        }
      });
    },
    contractTextModel() {
      var _this = this;
      contractTextModel({autionInfoId: this.autionInfoId}).then(res => {
        if (res.errno === 0) {
          _this.contractContent = res.data.contractTextModel;
          _this.contractDialogVisible = true;
        }
      });
    },
    clickContractShow() {
      if (this.contractContent == null || this.contractContent == '') {
        this.contractTextModel();
      } else {
        this.contractDialogVisible = true;
      }
    },
    //TODO 订单状态判断
    bidingStatue(startTime, endTime) {
      this.readOnly = true;
      if (Date.parse(formatNewDate(startTime)) > Date.parse(new Date())) {
        return 'preBid';
      } else if (Date.parse(formatNewDate(endTime)) > Date.parse(new Date())) {
        this.readOnly = false;
        return 'bidding';
      }
      return 'bided';
    },
    other(id) {
      //修改地址参数
      clearTimeout(this._timeout);
      this.$router.push({
        query: merge(this.$route.query, {autionInfoId: id})
      });
      this.autionInfoId = id;
      this.findByAutionInId();
    },

    findByAutionInId() {
      var _this = this;
      if (this.autionInfoId != null && this.autionInfoId != '') {
        readAutionInfoById({
          autionInfoId: this.autionInfoId
        }).then(res => {
          if (res.data.nowAmount != null) {
            //已竞卖
            res.data.status = 'bided';
            _this.readOnly = true;
          } else {
            if (_this.bidingStatue(res.data.startTime, res.data.endTime) === 'preBid') {
              res.data.status = 'preBid';
              res.data.nowAmount = res.data.upPrice;
              _this.countdown(res.data.startTime);
            } else if (_this.bidingStatue(res.data.startTime, res.data.endTime) === 'bidding') {
              res.data.status = 'bidding';
              _this.countdown(res.data.endTime);
            } else if (_this.bidingStatue(res.data.startTime, res.data.endTime) === 'bided') {
              res.data.status = 'bided';
            }

            // 计算当前价
            if (res.data.status === 'bidding' || res.data.status === 'bided') {
              const now = Date.parse(new Date());
              const overMsec = now - Date.parse(formatNewDate(res.data.startTime));
              const overSecond = parseInt(overMsec / 1000 / 60);
              if (overSecond < res.data.continueTime) {
                res.data.nowAmount = parseFloat((Number(res.data.upPrice) - overSecond * Number(res.data.failTactics)).toFixed(2));
              } else {
                res.data.nowAmount = parseFloat((Number(res.data.upPrice) - Number(res.data.continueTime) * Number(res.data.failTactics)).toFixed(2));
              }
              if (res.data.nowAmount < res.data.bottomPrice) {
                res.data.nowAmount = res.data.bottomPrice;
              }
            }
          }
          _this.tableData = res.data;
          if (_this.tableData.orderProcess === 'HK') {
            _this.tableData.orderProcess = '先货后款';
          }
          if (_this.tableData.orderProcess === 'KH') {
            _this.tableData.orderProcess = '先款后货';
          }
          _this.releaseAdmin = res.data.publisherId;
          _this.selectAutionById();
          _this.findByClientInfoId(res.data.clientInfoId);
          // if(new Date(this.tableData.endTime)<new Date()){
          //   _this.readOnly=true
          // }
        });
      }
    },
    // myFunction(){
    //       this.tableData=this.$route.query.data;
    // },
    // 暂时去掉倒计时
    countdown(time) {
      const _this = this;
      const end = Date.parse(formatNewDate(time));
      const now = Date.parse(new Date());
      const msec = end - now;

      // 计算当前价
      if (_this.tableData.status === 'bidding' || _this.tableData.status === 'bided') {
        const overMsec = now - Date.parse(formatNewDate(_this.tableData.startTime));
        const overSecond = parseInt(overMsec / 1000 / 60);
        if (overSecond < _this.tableData.continueTime) {
          // _this.tableData.nowAmount = Number(_this.tableData.upPrice) - overSecond * Number(_this.tableData.failTactics);
          _this.tableData.nowAmount = parseFloat((Number(_this.tableData.upPrice) - overSecond * Number(_this.tableData.failTactics)).toFixed(2));
        } else {
          _this.tableData.nowAmount = parseFloat((Number(_this.tableData.upPrice) - Number(_this.tableData.continueTime) * Number(_this.tableData.failTactics)).toFixed(2));
        }
        if (_this.tableData.nowAmount < _this.tableData.bottomPrice) {
          _this.tableData.nowAmount = _this.tableData.bottomPrice;
        }
      }

      if (msec <= 0) {
        clearTimeout(this._timeout);
        if (_this.tableData.status === 'preBid') {
          _this.tableData.status = 'bidding';
          _this.readOnly = false;
          _this.countdown(_this.tableData.endTime);
        } else if (_this.tableData.status === 'bidding') {
          _this.tableData.status = 'bided';
          _this.readOnly = true;
          _this.day = 0;
          _this.hour = '00';
          _this.minute = '00';
          _this.second = '00';
        }
      } else {
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt((msec / 1000 / 60 / 60) % 24);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        this.day = d;
        this.hour = h > 9 ? h : '0' + h;
        this.minute = m > 9 ? m : '0' + m;
        this.second = s > 9 ? s : '0' + s;
        this._timeout = setTimeout(function() {
          _this.countdown(time);
        }, 1000);
      }
    },
    // 加入采购单
    addCart() {
      var _this = this;
      if (_this.readOnly) {
        return;
      }

      if (!this.isLogin) {
        this.$confirm('当前未登录, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            _this.$router.push({
              path: '/home/Login'
            });
          })
          .catch(() => {});
        return;
      }

      if (this.$refs.CFCARef.checkIsIE()) {
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo();
        if (!this.certInfo) return;
        //  this.$message.info('未检测到证书信息')
      }
      localStorage.setItem('certInfo', this.certInfo);

      findById({id: _this.releaseAdmin})
        .then(res => {
          if (res.data.parentId == 0) {
            //主账号
            if (localStorage.getItem('userId') == res.data.id || localStorage.getItem('parentId') == res.data.id) {
              _this.mySelf = true;
            } else {
              _this.mySelf = false;
            }
          } else {
            //子账号
            if (localStorage.getItem('userId') == res.data.id || localStorage.getItem('parentId') == res.data.parentId || localStorage.getItem('userId') == res.data.parentId) {
              _this.mySelf = true;
            } else {
              _this.mySelf = false;
            }
          }

          if (_this.mySelf) {
            _this.$message.warning('你不能购买自己企业商品');
            return;
          }

          _this.biddingLoading = true;
          selectIsAution({
            autionId: _this.autionInfoId,
            amount: _this.tableData.nowAmount == undefined ? _this.tableData.upPrice : _this.tableData.nowAmount,
            proNum: _this.tableData.amount
          }).then(res => {
            if (res.errno == 0) {
              setTimeout(() => {
                _this.biddingLoading = false;
                _this.biddingStatus = {
                  show: true,
                  status: 'success'
                };
              }, 1000);
            } else {
              setTimeout(() => {
                _this.biddingLoading = false;
                _this.biddingStatus = {
                  show: true,
                  status: 'error'
                };
              }, 1000);
            }
          });
        })
        .catch(err => {
          console.log('11', err);
        });
    },
    dtsCategoryIdFilter(status) {
      return dtsCategory[status];
    },
    newestLvdingPrice() {
      newestLvdingPrice().then(response => {
        this.lvPrice = response.data;
      });
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    clearTimeout(this._timeout); // 当离开页面时，清除倒计时
    localStorage.removeItem('certInfo');
  }
};
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.head_h3 {
  position: relative;
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 18px;
  color: #333333;
  margin: 0;

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: '';
    width: 4px;
    height: 20px;
    background-image: linear-gradient(to bottom, #acb2ca, #33a2c5);
    margin-top: -10px;
  }
}
.wrap {
  width: 1200px;
  margin: 0 auto 30px auto;

  .breadcrumb {
    margin: 18px 0;
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    overflow: hidden;
    position: relative;

    .main {
      flex: 1;

      .info {
        background: #fff;
        padding: 22px;
        display: flex;
        justify-content: space-between;

        .image-box {
          width: 340px;
          height: 340px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
        .info-right {
          flex: 1;
          margin-left: 24px;
          padding-top: 8px;

          .name {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .count-down-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-top: 24px;
            background: #e13c45;
            background-image: linear-gradient(to right, #e23d44, transparent), repeating-linear-gradient(-45deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1) 20px, transparent 0, transparent 40px);

            .tit {
              width: 60px;
              height: 100%;
              color: #ffffff;
              font-size: 16px;
              background: #d91516;
              padding: 10px;
              box-sizing: border-box;
              text-align: center;
              line-height: 19px;
            }
            .count-down-info {
              display: flex;
              align-items: center;
              padding-left: 26px;
              font-size: 14px;
              color: #ffffff;

              .time {
                font-size: 16px;
              }
              .unit {
                color: #eed990;
                margin: 0 4px;
              }
            }
          }
          .box1-wrap {
            margin-top: 24px;
            .box1 {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              // font-size: 14px;

              span {
                width: 110px;
                text-align: justify;
                text-align-last: justify;
                color: #999999;
                padding: 0 22px;
                box-sizing: border-box;
              }
              p {
                color: #333333;

                &.price {
                  color: #ff4400;
                  font-weight: bold;
                  font-size: 18px;
                }
              }
              &.price {
                background: #effaff;
                padding: 16px 0;
                margin-top: 30px;

                p {
                  color: #fe4301;
                  font-weight: bold;
                  font-size: 26px;
                }
              }
            }
          }
          .addCart {
            width: 152px;
            height: 54px;
            color: #fff;
            text-align: center;
            line-height: 54px;
            font-weight: bold;
            border-radius: 3px;
            margin: 24px 0 0 22px;
            background-image: linear-gradient(to right, #e23d44, #e13c45);
            cursor: pointer;
            font-size: 16px;
          }
          .readOnly {
            background-image: linear-gradient(to right, #d7d4d4, #c2c0c1);
          }
        }
      }
      .explain {
        background: #fff;
        margin-top: 16px;

        h3 {
          .head_h3();
          font-size: 14px;
        }
        .explain-info {
          padding: 24px;

          & @{deep} {
            color: #333333;
            font-size: 14px;
            line-height: 24px;
          }
          & @{deep} img {
            width: 100%;
          }
        }
      }
    }

    .sidebar {
      width: 287px;
      margin-left: 15px;

      .publish {
        background: #fff;

        h3 {
          .head_h3();
          font-size: 14px;
        }
        .publish-info {
          padding: 24px;
          // font-size: 14px;
          // font-weight: bold;
          color: #333333;

          p {
            margin-bottom: 20px;

            .see {
              color: #2ca2c6;
              cursor: pointer;
              margin-left: 16px;
            }
          }
        }
      }
      .bidding {
        background: #fff;
        margin-top: 17px;

        h3 {
          .head_h3();
        }
        .bidding-list {
          padding: 24px 22px;

          .bidding-item {
            display: flex;
            margin-bottom: 45px;

            .bidding-image {
              width: 80px;
              height: 80px;

              img {
                width: 100%;
              }
            }
            .bidding-info {
              flex: 1;
              margin-left: 17px;

              p {
                font-size: 14px;
                color: #333333;
                line-height: 20px;

                .price {
                  color: #ff4400;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .bidding-status {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .good-box {
        position: absolute;
        top: 11px;
        left: 14px;
        right: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 164px;

        &.bg1 {
          background: #e6ffde;
        }
        &.bg2 {
          background: #ffebe9;
        }
        .good-box-in {
          display: flex;
          width: 305px;
          background: #ffffff;
          padding: 21px 15px;
          box-sizing: border-box;

          .img-box {
            width: 80px;
            height: 80px;
            overflow: hidden;
          }
          .info {
            margin-left: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .name {
              width: 160px;
              font-size: 14px;
              color: #333333;
              line-height: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .info-item {
              font-size: 12px;
              color: #888888;
              line-height: 1;

              &:last-child {
                margin-top: -4px;
              }
              .tit {
                display: inline-block;
                width: 50px;
                text-align: justify;
                text-align-last: justify;
              }
              .sign {
                color: #ff4400;
              }
              .price {
                font-size: 18px;
                color: #ff4400;
              }
            }
          }
        }
      }
      .status-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .status-box-info {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .el-icon-success {
            font-size: 44px;
            color: #55c176;
            margin-right: 10px;
          }
          .el-icon-error {
            font-size: 44px;
            color: #d91516;
            margin-right: 10px;
          }
          .txst {
            font-size: 16px;
          }
          .desc {
            font-size: 13px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  .bidding-loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-info {
      width: 150px;
      background: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      text-align: center;
      box-sizing: border-box;

      .dotting {
        margin-bottom: 30px;

        .dotting-item {
          width: 10px;
          height: 10px;
          margin: 0 6px;
          background-color: #2ba2c5;
          border-radius: 100%;
          display: inline-block;
          -webkit-animation: bouncedelay 2s infinite ease-in-out;
          animation: bouncedelay 2s infinite ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .dotting1 {
          -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
        }
        .dotting2 {
          -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
        }
        @-webkit-keyframes bouncedelay {
          0%,
          80%,
          100% {
            opacity: 0.5;
          }
          40% {
            opacity: 1;
          }
        }
        @keyframes bouncedelay {
          0%,
          80%,
          100% {
            opacity: 0.5;
          }
          40% {
            opacity: 1;
          }
        }
      }
      p {
        font-size: 15px;
      }
    }
  }
}
</style>
